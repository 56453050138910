import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { setPartner_Id, setUserAuth } from '../../utils/LocalStorageUtils';
import { apiRegister } from '../apis/apis';
import { useSnackbar } from '../../context/SnackbarContext';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { fetchPartnerIdFromApi } from '../../utils/FetchLogo';
import AuthLayout from '../components/layout/AuthLayout';
import { DirtyLens } from '@mui/icons-material';

const Register = () => {
    const history = useHistory();
    const location = useLocation();
    const { showSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(false);
    const [rawPhone, setRawPhone] = useState('');
    const [dialCode, setDialCode] = useState('');
    const [data, setData] = useState({
        fullName: '',
        email: '',
        mobileNumber: '',
    });

    const [partnerId, setPartnerId] = useState(0);


    const handle = (e) => {
        const newdata = { ...data };
        newdata[e.target.id] = e.target.value;
        setData(newdata);
    };

    const onPhoneNumberChange = (value, data) => {
        setRawPhone(value.slice(data.dialCode.length));
        setDialCode(data.dialCode);
        setData((prevState) => ({
            ...prevState,
            mobileNumber: value,
        }));
    };

    const checkAuthentication = () => {
        const { fullName, email, mobileNumber } = data;

        if (fullName.trim() === '' || mobileNumber.trim() === '' || email.trim() === '') {
            showSnackbar('Field cannot be empty');
            return false;
        }
        return true;
    };

    const submit = (e) => {
        e.preventDefault();

        const isValid = checkAuthentication();
        if (!isValid) return;

        setIsLoading(true);

        // Save current data into localStorage
        localStorage.setItem('fullName', data.fullName);
        localStorage.setItem('email', data.email);
        localStorage.setItem('mobileNumber', data.mobileNumber);
        localStorage.setItem('rawPhone', rawPhone);
        localStorage.setItem('dialCode', dialCode);
        localStorage.setItem('countryCode', dialCode);

        apiRegister({
            fullName: data.fullName,
            countryCode: dialCode,
            mobileNumber: rawPhone,
            email: data.email,
        })
            .then((res) => {
                history.push({ pathname: '/otp', state: { otpSentTo: res.otpSentTo, register:true } });
            })
            .catch((err) => {
                showSnackbar(err);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        fetchPartnerIdFromApi(
            setPartnerId,
            setPartner_Id,
            showSnackbar
          );
      }, []);
      
    useEffect(() => {
    if (location.state && location.state.editMode) {
            const fullName = localStorage.getItem('fullName') || '';
            const email = localStorage.getItem('email') || '';
            const mobileNumber = localStorage.getItem('mobileNumber') || '';
            const rawPhoneValue = localStorage.getItem('rawPhone') || '';
            const dialCodeValue = localStorage.getItem('dialCode') || '';
            setData({ fullName, email, mobileNumber });
            setRawPhone(rawPhoneValue);
            setDialCode(dialCodeValue);
        } else {
            setData({ fullName: '', email: '', mobileNumber: '' });
            setRawPhone('');
            setDialCode('');
        }
    }, [location.state]);

    return (
        <AuthLayout title="Create an Account">
            <form onSubmit={submit}>
                <p className="fs-normal-medium text-primary mb-4">
                    Please enter your name exactly as it appears on your PAN card.
                </p>

                <div className="form-floating mb-3">
                    <input
                        className="form-control"
                        placeholder="Name"
                        onChange={handle}
                        name="fullName"
                        value={data.fullName}
                        id="fullName"
                    />
                    <label htmlFor="fullName">Name</label>
                </div>
                <div className="form-floating mb-3">
                    <PhoneInput
                        specialLabel=""
                        className="react-tel-input form-control"
                        id="mobileNumber"
                        country={'in'}
                        autoFormat={false}
                        placeholder="Mobile"
                        value={data.mobileNumber} // Correctly set phone number
                        onChange={onPhoneNumberChange}
                    />
                    <label htmlFor="mobileNumber">Mobile</label>
                </div>
                <div className="form-floating mb-3">
                    <input
                        value={data.email}
                        onChange={handle}
                        name="email"
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder=" "
                    />
                    <label htmlFor="email">Email ID</label>
                </div>
                <div className="d-flex mt-3">
                    <button type="submit" disabled={isLoading} className="btn btn-primary px-3 py-2">
                        Sign Up
                    </button>
                </div>
                <p className="mt-4">
                    Already have an account?{' '}
                    <a className="text-decoration-none" href="/">
                        Sign In
                    </a>
                </p>
            </form>
        </AuthLayout>
    );
};

export default Register;
