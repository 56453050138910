import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { apiSwitchAccount, getWallet } from "../apis/apis";
import { formattedCurrency } from "../../utils/userUtilities";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { IconButton, Menu, MenuItem, Tooltip, Dialog, Divider } from "@mui/material";
import ReceiptIcon from '@mui/icons-material/Receipt';
import DocumentScanner from '@mui/icons-material/DocumentScanner';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { setItem, LSK_MCA_STATUS, getItem, FLAG_REDIRECT_FROM_PREVIEW, HAS_MULTIPLE_ACCOUNTS, MULTIPLE_ACCOUNT_LIST, getPartner_Id, setPartner_Id } from '../../utils/LocalStorageUtils';
import { apiEmailLoginGetOtp, apiEmailLoginInit, apiGetOTP } from '../apis/apis';
import { useSnackbar } from '../../context/SnackbarContext';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import { Close } from "@mui/icons-material";
import { fetchPartnerIdFromApi } from "../../utils/FetchLogo";

function NavBar({ showOnlyMenu }) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const { showSnackbar } = useSnackbar()
  const [openAccountsDialog, setOpenAccountsDialog] = useState(false);
  const [walletAmount, setWalletAmount] = useState(0.00);
  const [accountsList, setAccountsList] = useState([]);
  const [logo, setLogo] = useState(null); 
  const open = Boolean(anchorEl);
  const [logoError, setLogoError] = useState(null);
  const [partnerId, setPartnerId] = useState(getPartner_Id());


  const fetchLogo = (partnerId) => {
    return `${process.env.REACT_APP_BASE_URL}/logo/${partnerId}`;  
  };

  useEffect(() => {
    if (!partnerId) {
      fetchPartnerIdFromApi(
        setPartnerId,
        setPartner_Id,
        showSnackbar
      );
      setLogo(fetchLogo(partnerId));
    } else {
      setLogo(fetchLogo(partnerId));
      setLogoError(null); 
    }
  }, [partnerId]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenAccountsDialog(false);
  };

  const handleProfile = () => {
    history.push("/user/profile");
    handleClose();
  };

  const handlePaymentHistory = () => {
    history.push("/user/transactions");
    handleClose();
  }

  const handleLogout = () => {
    localStorage.clear();
    history.push("/");
    handleClose();
  };

  const handleDocument = () => {
    history.push("/user/documents");
    handleClose();
  }

  const getAccountsList = () => {
    const data = getItem(MULTIPLE_ACCOUNT_LIST);
    if (data) {
        setAccountsList(JSON.parse(data));
    }
  }

  const handleSwitchAccounts = () => {
    setOpenAccountsDialog(true);
  }

  const getSelectedAccount = (userRid) => {
    apiSwitchAccount({ userRid: userRid })
      .then((data) => {
        const { user } = data;
        localStorage.setItem("fullName", user.fullName);
        localStorage.setItem("email", user.email);
        localStorage.setItem("mobileNumber", user.mobile);
        localStorage.setItem("countryCode", user.countryCode);
        localStorage.setItem("dialCode", user.countryCode);
        setItem(LSK_MCA_STATUS, user.mcaStatus);
        history.push("/user/home");
        window.location.reload();
      })
      .catch((err) => {
        showSnackbar(err)
      })
      .finally(() => {
        setAccountsList([]);
        setOpenAccountsDialog(false);
        handleClose();
      });
  }

  const getWalletData = async () => {
    const walletData = await getWallet();

    if (walletData.status) {
      const balanceAmt = walletData?.data?.balanceAmt ?? 0;
      setWalletAmount(balanceAmt);
    }
  }

  useEffect(() => {
    getWalletData();
    getAccountsList();
  }, []);

  return (
    <>
      {
        showOnlyMenu
          ? <div className="d-flex justify-content-end align-items-center py-4 px-5">
            <IconButton
              color="primary"
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <PermIdentityIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}>
              <MenuItem className="px-4 py-3" onClick={handleLogout}>
                <LogoutRoundedIcon />
                <span className="ms-3">Logout</span>
              </MenuItem>
            </Menu>
          </div>
          : <div className="d-flex align-items-center py-4 px-5 border-bottom">
            <div className="d-flex gap-5 align-items-center flex-grow-1">
              <img
                src={logo}  
                alt={"logo"}
                //  width="180"
                onClick={() => history.push("/user/home")}
              />
              <div className="d-flex gap-2 align-items-center flex-grow-1">
                <div className="fs-large-medium custom-link py-0 pe-4 m-0" onClick={() => history.push("/user/home")}>
                  Portfolio
                </div>
              </div>
            </div>
            <div className="d-flex gap-4 align-items-center">
              <div className="d-flex gap-1 align-items-center">
                <span className="fs-med-medium text-primary">
                  Hello, {localStorage.getItem("fullName") || 'Investor'}
                </span>
                <span className="fs-med-medium">|</span>
                <span className="fs-med-medium text-dark">
                  Funds Available {formattedCurrency(walletAmount)}
                  {getItem(FLAG_REDIRECT_FROM_PREVIEW) ?
                    <span className="custom-disable ms-1">
                      <Tooltip title="Wallet top-up">
                        <AddCircleIcon />
                      </Tooltip>
                    </span> :
                    <span className="custom-link ms-1" 
                    onClick={() => history.push("/user/wallet-topup")}>
                      <Tooltip title="Wallet top-up">
                        <AddCircleIcon />
                      </Tooltip>
                    </span>}
                </span>
              </div>
              <IconButton
                color="primary"
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <PermIdentityIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}>
                <MenuItem className="px-4 py-3" onClick={handleProfile}>
                  <AccountCircleRoundedIcon />
                  <span className="ms-3">My Profile</span>
                </MenuItem>
                <MenuItem className="px-4 py-3" onClick={handlePaymentHistory}>
                  <ReceiptIcon />
                  <span className="ms-3">Payment History</span>
                </MenuItem>
                <MenuItem className="px-4 py-3" onClick={handleDocument}>
                  <DocumentScanner />
                  <span className="ms-3">Documents</span>
                </MenuItem>
                {!getItem(FLAG_REDIRECT_FROM_PREVIEW) && getItem(HAS_MULTIPLE_ACCOUNTS,true) && accountsList?.length ?
                  <MenuItem className="px-4 py-3" onClick={handleSwitchAccounts}>
                    <SwitchAccountIcon />
                    <span className="ms-3">Switch Account</span>
                  </MenuItem> : null}
                <MenuItem className="px-4 py-3" onClick={handleLogout}>
                  <LogoutRoundedIcon />
                  <span className="ms-3">Logout</span>
                </MenuItem>
              </Menu>
              <Dialog open={openAccountsDialog} className="d-flex justify-content-end " >
                <div className="d-flex flex-column justify-content-end p-3" style={{ minWidth: '260px' }}>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div>
                      <h5 className="fs-large-semibold text-primary">Choose Your Login</h5>
                    </div>
                    <div>
                      <IconButton color="secondary" onClick={handleClose}>
                        <Close fontSize="medium" />
                      </IconButton>
                    </div>
                  </div>
                  {
                    accountsList?.map((data, key) => (
                      <div key={key}>
                        {key > 0 ? <Divider /> : ''}
                        <div className="d-flex gap-3 align-items-center py-2" style={{ cursor: 'pointer' }} onClick={() => getSelectedAccount(data.userRid)}>
                          <AccountCircleIcon sx={{ fontSize: '40px' }} />
                          <span className="fs-med-regular">{data?.mobile}</span>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </Dialog>
            </div>
          </div>
      }
    </>
  );
}

export default NavBar;